import { defaultColumnProps, TableColumnProps } from '../../../../custom_components/CustomTableHeader'
import { LeaderBoard } from './dataModal'

export const leaderboardData: LeaderBoard[] = [
	{
		_id: '1',
		rank: '1',
		name: 'John Doe',
		gender: 'Male',
		profession: 'Software Engineer',
		country: 'USA',
		state: 'California',
		city: 'San Francisco',
		score: '9800',
	},
	{
		_id: '2',
		rank: '2',
		name: 'Jane Smith',
		gender: 'Female',
		profession: 'Data Scientist',
		country: 'Canada',
		state: 'Ontario',
		city: 'Toronto',
		score: '9200',
	},
	{
		_id: '3',
		rank: '3',
		name: 'Emily Johnson',
		gender: 'Female',
		profession: 'Product Manager',
		country: 'UK',
		state: 'England',
		city: 'London',
		score: '8900',
	},
	{
		_id: '4',
		rank: '4',
		name: 'Michael Brown',
		gender: 'Male',
		profession: 'UX Designer',
		country: 'Australia',
		state: 'New South Wales',
		city: 'Sydney',
		score: '8700',
	},
	{
		_id: '5',
		rank: '5',
		name: 'Sarah Davis',
		gender: 'Female',
		profession: 'Marketing Specialist',
		country: 'Germany',
		state: 'Bavaria',
		city: 'Munich',
		score: '8500',
	},
	{
		_id: '6',
		rank: '6',
		name: 'James Wilson',
		gender: 'Male',
		profession: 'DevOps Engineer',
		country: 'India',
		state: 'Karnataka',
		city: 'Bangalore',
		score: '8300',
	},
	{
		_id: '7',
		rank: '7',
		name: 'Sophia Martinez',
		gender: 'Female',
		profession: 'Business Analyst',
		country: 'Mexico',
		state: 'Mexico City',
		city: 'Mexico City',
		score: '8100',
	},
	{
		_id: '8',
		rank: '8',
		name: 'William Clark',
		gender: 'Male',
		profession: 'Network Administrator',
		country: 'South Africa',
		state: 'Gauteng',
		city: 'Johannesburg',
		score: '7800',
	},
	{
		_id: '9',
		rank: '9',
		name: 'Olivia Lewis',
		gender: 'Female',
		profession: 'Graphic Designer',
		country: 'Brazil',
		state: 'Sao Paulo',
		city: 'Sao Paulo',
		score: '7500',
	},
	{
		_id: '10',
		rank: '10',
		name: 'Liam Walker',
		gender: 'Male',
		profession: 'Cybersecurity Specialist',
		country: 'Singapore',
		state: 'Central Region',
		city: 'Singapore',
		score: '7300',
	},
]

export const leaderboardHeaderData: TableColumnProps[] = [
	{
		...defaultColumnProps,
		id: 'rank',
		label: 'Rank',
		width: 100,
	},
	{
		...defaultColumnProps,
		id: 'name',
		label: 'Name',
		width: 180,
	},
	{
		...defaultColumnProps,
		id: 'gender',
		label: 'Gender',
		width: 105,
	},
	{
		...defaultColumnProps,
		id: 'profession',
		label: 'Profession',
		width: 135,
	},
	{
		...defaultColumnProps,
		id: 'country',
		label: 'Country',
		width: 135,
	},
	{
		...defaultColumnProps,
		id: 'state',
		label: 'State',
		width: 150,
	},
	{
		...defaultColumnProps,
		id: 'city',
		label: 'City',
		width: 145,
	},
	{
		...defaultColumnProps,
		id: 'score',
		label: 'Score',
		width: 90,
	},
]

import React, { useState, useRef, useEffect } from 'react'
import { Box, Typography, Link, useMediaQuery, useTheme, Dialog, DialogTitle } from '@mui/material'
import { useAppDispatch, useLocalize } from '../custom_components/CustomHooks'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { localeKeys } from '../resources/localeConstants'
import CustomizedButton from '../custom_components/CustomButton'
import { flexStyles } from '../app_features/flexStyles'
import CustomIcon from '../custom_components/CustomIcon'
import CustomTextfield from '../custom_components/CustomTextfield'
import { sendReautheticateOtp } from './home_thunk/homeSlice'

interface VerifyAccountProps {
	openDialog: boolean
	onVerify: (otp: string) => void
	isIncorrectOtp?: boolean
	setIsIncorrectOtp?: React.Dispatch<React.SetStateAction<boolean>>
	sendOtpToEmail: (email: string) => void
	email: string
	onVerifyClose: () => void
	isDelete?: boolean
}

export default function VerifyAccount({
	onVerify,
	openDialog,
	// onVerifyClose,
	isIncorrectOtp = false,
	setIsIncorrectOtp,
	sendOtpToEmail,
	email,
	isDelete = false,
}: VerifyAccountProps) {
	const t = useLocalize()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [otp, setOtp] = useState(['', '', '', ''])
	const inputRefs = useRef<(HTMLInputElement | null)[]>([])
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (openDialog) {
			// Reset OTP when dialog opens
			setOtp(['', '', '', ''])
			// Focus on the first input field with a slight delay
			setTimeout(() => {
				if (inputRefs.current[0]) {
					inputRefs.current[0].focus()
				}
			}, 100)
		}
	}, [openDialog])

	const handleOtpChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value.replace(/\D/g, '')
		if (value.length <= 1) {
			const newOtp = [...otp]
			newOtp[index] = value
			setOtp(newOtp)

			setIsIncorrectOtp?.(false)

			if (value && index < 3) {
				inputRefs.current[index + 1]?.focus()
			}
		}
	}

	const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace' && !otp[index] && index > 0) {
			inputRefs.current[index - 1]?.focus()
		}
	}

	const handleVerify = () => {
		const otpString = otp.join('')
		if (otpString.length === 4) {
			onVerify(otpString)
		}
	}

	const handleResendOtp = async () => {
		setOtp(['', '', '', ''])
		setIsIncorrectOtp?.(false)
		inputRefs.current[0]?.focus()
		sendOtpToEmail(email)
		await dispatch(sendReautheticateOtp())
	}

	return (
		<Dialog
			open={openDialog}
			// onClose={onVerifyClose}
			PaperProps={{
				sx: {
					borderRadius: '20px',
					backgroundColor: 'background.surface',
					backgroundImage: 'none',
					p: isMobile ? '14px' : '24px',
				},
			}}
		>
			<DialogTitle sx={{ ...flexStyles.flexCenter, pb: 0 }}>
				<CustomIcon name={iconKeys.getFitNowIcon} style={{ width: '89px', height: '89px' }} svgStyle='width: 89px; height: 89px' />
			</DialogTitle>
			<Box sx={{ ...flexStyles.flexColumnJustifyAlignCenter }} gap={2}>
				<Typography variant={typographyKeys.h2}>{t(localeKeys.verifyThisAccount)}</Typography>
				<Typography variant={typographyKeys.subtitle1} sx={{ textAlign: 'center' }}>
					{t(localeKeys.otpMobileMessage)}
				</Typography>
				<Box display='flex' gap={2}>
					{otp.map((digit, index) => (
						<CustomTextfield
							key={index}
							textFieldProps={{
								id: `otp-${index}`,
								name: 'otp',
								type: 'tel',
								value: digit,
								onChange: handleOtpChange(index),
								onKeyDown: handleKeyDown(index),
							}}
							propSx={{
								width: isMobile ? '30px' : '52px',
								height: isMobile ? '40px' : '48px',
								input: { textAlign: 'center' },
								maxLength: '1',
							}}
							fieldSx={{ borderRadius: '12px' }}
							error={isIncorrectOtp}
							centerAligned={true}
							inputRef={(el) => (inputRefs.current[index] = el)}
						/>
					))}
				</Box>
				{isIncorrectOtp && (
					<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.error' }}>
						{t(localeKeys.incoorectOtp)}
					</Typography>
				)}
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
					<Typography variant={typographyKeys.subtitle1}>{t(localeKeys.didntRecieve)}</Typography>
					<Typography
						variant={typographyKeys.body2}
						align='center'
						sx={{ cursor: 'pointer', color: 'text.subHeading' }}
						onClick={handleResendOtp}
					>
						{t(localeKeys.resendOtp)}
					</Typography>
				</Box>

				<CustomizedButton
					textprops={{
						text: t(isDelete ? localeKeys.delete : localeKeys.update),
					}}
					sxprops={{
						minWidth: isMobile ? '115px' : '170px',
						maxWidth: isMobile ? '115px' : '170px',
						height: isMobile ? '40px' : '50px',
						borderRadius: '60px',
						backgroundColor: 'background.background2',
					}}
					onClick={handleVerify}
					disabled={otp.some((o) => o === '')}
				/>
				<Typography variant={typographyKeys.subtitle1} align='center' sx={{ mt: '10px', maxWidth: '378px', color: 'text.primary' }}>
					{t(localeKeys.termsText)}{' '}
					<Link
						href={'https://games.someshwara.com/webapp-assets/Terms_Conditions_FitPlay.html'}
						target='_blank'
						rel='noopener noreferrer'
						sx={{ textDecoration: 'none' }}
					>
						<Typography component='span' sx={{ color: 'text.primary' }}>
							{t(localeKeys.termsAndConditionsText)}
						</Typography>
					</Link>{' '}
					{t(localeKeys.and)}{' '}
					<Link
						href={'https://games.someshwara.com/webapp-assets/Privacy_Policy_FitPlay.html'}
						target='_blank'
						rel='noopener noreferrer'
						sx={{ textDecoration: 'none' }}
					>
						<Typography component='span' sx={{ color: 'text.primary' }}>
							{t(localeKeys.privacyText)}
						</Typography>
					</Link>
				</Typography>
			</Box>
		</Dialog>
	)
}

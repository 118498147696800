import React, { ReactNode, useEffect, useState } from 'react'
import { createTheme, Theme, ThemeOptions, ThemeProvider, useMediaQuery } from '@mui/material'
import { initialTheme } from './MuiTheme'
import { getTheme } from './CustomHooks'
import Loader from './Loader'
import { commonConfig } from '../environment'
import { stringConstants } from '../utils/commonConstants'

export const ColorModeContext = React.createContext({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	toggleColorMode: () => {},
	mode: 'dark',
})

const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
	const [themeOptions, setThemeOptions] = useState<ThemeOptions | null>(null)
	const [appliedTheme, setAppliedTheme] = useState<Theme>(initialTheme)
	const isMobile = useMediaQuery(appliedTheme.breakpoints.down('sm'))
	const [mode, setMode] = useState<'light' | 'dark'>(() => {
		return localStorage.getItem('themeMode') === 'light' ? 'light' : 'dark'
	})
	const [isThemeLoaded, setIsThemeLoaded] = useState(false)

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => {
					const newMode = prevMode === 'light' ? 'dark' : 'light'

					// Save the new theme mode in localStorage
					localStorage.setItem('themeMode', newMode)

					// Dispatch a CustomEvent to notify about the theme change
					const themeChangeEvent = new CustomEvent('themeChange', {
						detail: { themeMode: newMode },
					})
					window.dispatchEvent(themeChangeEvent)

					return newMode
				})
			},
			mode,
		}),
		[mode]
	)

	const getThemeOptions = async () => {
		const newtheme = await getTheme(mode === 'light', isMobile)
		setThemeOptions(newtheme)
	}

	const loadThemeCSS = async () => {
		if (commonConfig.app.theme === stringConstants.default) {
			if (mode === 'dark') {
				await import('../resources/default/theme/palette1/css/datePickerDark.css' as string)
			} else {
				await import('../resources/default/theme/palette1/css/datePicker.css' as string)
			}
		} else {
			if (mode === 'dark') {
				await import('../resources/whitelabel/css/datePickerDark.css' as string)
			} else {
				await import('../resources/whitelabel/css/datePicker.css' as string)
			}
		}
	}

	useEffect(() => {
		const initializeTheme = async () => {
			await getThemeOptions()
			await loadThemeCSS()
			setIsThemeLoaded(true)
		}
		initializeTheme()
	}, [isMobile, mode])

	useEffect(() => {
		if (themeOptions) {
			const newTheme = createTheme({
				...themeOptions,
				palette: {
					...themeOptions.palette,
					mode,
				},
			})
			setAppliedTheme(newTheme)
		}
	}, [themeOptions, mode])

	if (!isThemeLoaded) {
		return <Loader />
	}

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	)
}

export default MuiThemeProvider

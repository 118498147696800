// authSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { localeKeys } from '../../../resources/localeConstants'
import {
	googleLoginCallbackThunk,
	refreshTokenThunk,
	sendOtpThunk,
	updateProfileThunk,
	verifyEmailOtpThunk,
	verifyMobileOtpThunk,
	verifyOtpThunk,
} from './signupThunk'
import { UpdateProfileData } from './signupDatamodal'
import { addAuthTypeToLocalStorage, addTokenToLocalStorage } from '../../../utils/localStorage'

// Define the shape of the authentication state
interface signupProps {
	authToken: string
	error: string
	email: string
	userData: UpdateProfileData | null
}

// Initial state for the authentication slice
const initialState: signupProps = {
	authToken: '',
	error: '',
	email: '',
	userData: null,
}

// Async thunk to handle the sendotp  API call
export const sendOtp = createAsyncThunk('signUpFlow/sendOtp', sendOtpThunk)

export const verifyOtp = createAsyncThunk('signUpFlow/verifyOtp', verifyOtpThunk)
export const verifyOtpForEmail = createAsyncThunk('signUpFlow/verifyEmailOtp', verifyEmailOtpThunk)
export const verifyOtpforPhone = createAsyncThunk('signUpFlow/verifyMobileOtp', verifyMobileOtpThunk)
export const updateProfile = createAsyncThunk('signUpFlow/updateProfile', updateProfileThunk)
export const refreshToken = createAsyncThunk('signUpFlow/refreshToken', refreshTokenThunk)

export const googleLoginCallback = createAsyncThunk('signUpFlow/googleLogin', googleLoginCallbackThunk)

// Authentication slice with reducers and extraReducers for async actions
const SignupSlice = createSlice({
	name: 'signUpFlow',
	initialState,
	reducers: {
		setAuthToken: (state, action) => {
			state.authToken = action.payload
		},
		setEmail: (state, action) => {
			state.email = action.payload
		},
	},
	extraReducers: (builder) => {
		// Handle the rejected case of the login API call
		builder.addCase(sendOtp.rejected, (state, action) => {
			if (action.payload === localeKeys.networkError) {
				state.error = localeKeys.unableToConnect
			} else {
				state.error = action.payload as string
			}
		})

		// Handle the fulfilled case of the login API call
		builder.addCase(verifyOtp.fulfilled, (state, action) => {
			if (action.payload.data.data?.data?.authToken) {
				state.authToken = action.payload.data.data.data.authToken
				addTokenToLocalStorage(action.payload.data.data.data.authToken)
				addAuthTypeToLocalStorage(action.payload.data.data.authType)
			}
		})

		// Handle the rejected case of the login API call
		builder.addCase(verifyOtp.rejected, (state, action) => {
			if (action.payload === localeKeys.networkError) {
				state.error = localeKeys.unableToConnect
			} else {
				state.error = action.payload as string
			}
		})

		builder.addCase(verifyOtpForEmail.fulfilled, (state, action) => {
			state.authToken = action.payload.data.data.accessToken
			addTokenToLocalStorage(action.payload.data.data.accessToken)
			addAuthTypeToLocalStorage(action.payload.data.data.authType)
		})

		// Handle the rejected case of the login API call
		builder.addCase(verifyOtpForEmail.rejected, (state, action) => {
			if (action.payload === localeKeys.networkError) {
				state.error = localeKeys.unableToConnect
			} else {
				state.error = action.payload as string
			}
		})

		builder.addCase(googleLoginCallback.fulfilled, (state, action) => {
			state.authToken = action.payload.data.data.accessToken
			addTokenToLocalStorage(action.payload.data.data.accessToken)
			addAuthTypeToLocalStorage(action.payload.data.data.authType)
		})

		// Handle the rejected case of the login API call
		builder.addCase(googleLoginCallback.rejected, (state, action) => {
			if (action.payload === localeKeys.networkError) {
				state.error = localeKeys.unableToConnect
			} else {
				state.error = action.payload as string
			}
		})

		builder.addCase(refreshToken.fulfilled, (state, action) => {
			state.authToken = action.payload.data.data.accessToken
			addTokenToLocalStorage(action.payload.data.data.accessToken)
			// addAuthTypeToLocalStorage(action.payload.data.data.authType)
		})

		// Handle the rejected case of the login API call
		builder.addCase(refreshToken.rejected, (state, action) => {
			if (action.payload === localeKeys.networkError) {
				state.error = localeKeys.unableToConnect
			} else {
				state.error = action.payload as string
			}
		})

		builder.addCase(updateProfile.fulfilled, (state, action) => {
			// Extract the latest authToken (last item in the array)
			if (action.payload.data.data === 'invalid Number') return

			// Update the state with the new data
			state.userData = action.payload.data.data
		})
	},
})

export const { setAuthToken, setEmail } = SignupSlice.actions

export default SignupSlice.reducer

'use client'
import { Box, IconButton, InputAdornment, InputBaseComponentProps, Stack, Typography, useTheme } from '@mui/material'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CustomIcon from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { EmptyFunction } from '../app_features/types'
import { useLocalize } from './CustomHooks'
import { flexStyles } from '../app_features/flexStyles'

interface InlineDatePickerProps {
	label?: string
	value?: Date | null
	onSingleDateChange?: (date: Date | null) => void
	propSx?: InputBaseComponentProps & { height?: string; maxLength?: string }
	isRequired?: boolean
	readOnly?: boolean
	disabled?: boolean
	disableWithColor?: boolean
	helperText?: string
	helperTextColor?: string
	error?: boolean
	placeholder?: string
	handleClick: EmptyFunction
	handleClose: EmptyFunction
	inputLabel?: string
}

/**
 * CustomDateField: A component that provides a date picker with various functionalities based on the provided props.
 * @param onDateRangeChange - Function triggered when a date range is changed.
 * @param startDate - The start date in the range.
 * @param endDate - The end date in the range.
 * @param dateRange - A boolean indicating whether the date picker allows a range of dates.
 * @param setApplyButtonClicked - Function to handle click on the Apply button.
 * @param title - The label/title for the date picker.
 * @param value - The selected date or range of dates.
 * @param onSingleDateChange - Function triggered on selection of a single date.
 * @param propSx - CSS properties for width and height.
 * @param isRequired - A boolean indicating whether the field is required.
 * @returns ReactElement representing the custom date picker field.
 */
const CustomDateField = ({
	isRequired = false,
	label,
	value = null,
	onSingleDateChange,
	propSx,
	readOnly,
	disabled = false,
	helperText,
	helperTextColor,
	error = false,
	placeholder,
	handleClick,
	handleClose,
	disableWithColor,
	inputLabel,
}: InlineDatePickerProps) => {
	const localize = useLocalize()
	const theme = useTheme()
	const [focused, setFocused] = useState<boolean>(false)
	return (
		<div style={{ width: '100%' }}>
			{inputLabel && (
				<Box sx={{ ...flexStyles.flexRow, gap: '5px', mb: '5px' }}>
					<Typography sx={{ display: 'inline', color: 'text.defaultHeading' }} variant={typographyKeys.body2}>
						{inputLabel}
					</Typography>
					{isRequired ? (
						<Typography sx={{ display: 'inline', color: 'red.normalmain' }} variant={typographyKeys.body2}>
							{'*'}
						</Typography>
					) : null}
				</Box>
			)}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Stack direction='row' alignItems='center' spacing={1}>
					<DateField
						onClick={handleClick}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						label={
							label ? (
								<>
									{localize(label)}
									{isRequired && (
										<Typography
											sx={{
												display: 'inline',
												color: 'red.normalmain',
											}}
										>
											{' '}
											*
										</Typography>
									)}
								</>
							) : placeholder && !value && !focused ? (
								<>{localize(placeholder)}</>
							) : null
						}
						value={value ? dayjs(value) : null}
						format='DD/MM/YYYY'
						readOnly={readOnly}
						sx={{
							'& .MuiInputBase-input::placeholder': {
								fontWeight: 500,
								opacity: 1,
							},
							color: 'text.defaultHeading',
							fontWeight: 700,
							minHeight: propSx?.height ?? '50px',
							maxHeight: propSx?.height ?? '50px',
							width: propSx?.width ?? '100%',
							'& .MuiInputLabel-root': {
								...theme.typography.body2,
								color:
									disableWithColor && value && !error
										? 'red.normalmain'
										: error
										? 'red.normalmain'
										: value
										? 'orange.normalmain'
										: 'greyNew.normalmain',
								marginTop: '-3px',
								'&.Mui-focused': {
									color: disableWithColor ? 'red.normalmain' : 'orange.normalmain',
								},
							},
							'& .MuiOutlinedInput-root': {
								height: propSx?.height ?? '46px',
								borderRadius: '12px !important',
								backgroundColor: readOnly ? 'greyNew.lightborder' : 'background.secondary',
								color: 'text.defaultHeading',
								fontWeight: 700,
								'& fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'border.main',
									color: 'text.defaultHeading',
								},
								'&:hover fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'border.primary',
								},
								'&.Mui-focused fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'border.primary',
								},
								'&.Mui-focused.Mui-error fieldset': {
									border: '1px solid ',
									borderColor: 'border.primary',
								},
								'&.Mui-error fieldset': {
									border: '1px solid ',
									borderColor: 'border.primary',
								},
							},
						}}
						style={{ height: '50px !important' }}
						onChange={(newValue: string | Dayjs | null): void => {
							if (newValue) {
								const selectedDay = dayjs(newValue, 'DD/MM/YYYY').toDate()

								onSingleDateChange?.(selectedDay)
							} else {
								onSingleDateChange?.(null)
							}
							handleClose()
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<CustomIcon
										name={iconKeys.calendar}
										style={{ width: '16px', height: '16px' }}
										svgStyle='height: 16px; width: 16px'
									/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment position='end'>
									{disabled ? (
										<IconButton size='small'>
											<CustomIcon name={iconKeys.calendar} />
										</IconButton>
									) : (
										<IconButton onClick={handleClick} size='small'>
											<CustomIcon
												name={iconKeys.calendarTwo}
												style={{ width: '21px', height: '21px' }}
												svgStyle='height: 21px; width: 21px'
											/>
										</IconButton>
									)}
								</InputAdornment>
							),
						}}
						helperText={
							helperText ? (
								<Typography
									variant={typographyKeys.subtitle1}
									sx={{
										color: helperTextColor ?? 'text.tertiary',
									}}
								>
									{localize(helperText)}
								</Typography>
							) : (
								<></>
							)
						}
					/>
				</Stack>
			</LocalizationProvider>
		</div>
	)
}

export default CustomDateField

import { useTheme } from '@mui/material/styles'

export const defaultColors = {
	textPrimary: 'text.primary',
	red: 'red.normalmain',
	greyLightBorder: 'greyNew.lightborder',
	greyLightActive: 'greyNew.lightactive',
	greyLightHover: 'greyNew.lighthover',
	orangeMain: 'orange.normalmain',
	textTertiary: 'text.tertiary',
	white: 'common.white',
	disabledButtonColor: 'greyNew.lighthover',
	hoverBackgroundColor: 'common.white',
	textColor: 'common.white',
	typoGraphy: '#666666',
	background: '#ffffff',
}

export const newLightColors = {
	textPrimary: '#111827',
	red: '#EF4444',
	greyLightBorder: '#E2E8F0',
	greyLightActive: '#84CC16',
	greyLightHover: '#C9C9C9',
	orangeMain: '#84CC16',
	textTertiary: '#4B5563',
	white: '#F1F5F9',
	disabledButtonColor: '#F1F5F9',
	hoverBackgroundColor: '#84CC16',
	textColor: '#111827',
	typoGraphy: '#4B5563',
	background: '#ffffff',
}

export const newDarkColors = {
	textPrimary: '#f8fafc',
	red: '#EF4444',
	greyLightBorder: '#334155',
	greyLightActive: '#84CC16',
	greyLightHover: '#C9C9C9',
	orangeMain: '#84CC16',
	textTertiary: '#d1d5db',
	white: '#1e293b',
	disabledButtonColor: '#1e293b',
	hoverBackgroundColor: '#84CC16',
	textColor: '#f8fafc',
	typoGraphy: '#d1d5db',
	background: '#111827',
}

export const getColors = (isNewColors: boolean) => {
	const theme = useTheme()
	const isDarkMode = theme.palette.mode === 'dark'

	if (isNewColors) {
		return isDarkMode ? newDarkColors : newLightColors
	}
	return defaultColors
}

import { useMediaQuery, useTheme } from '@mui/material'

interface FontStyles {
	fontSize: string
	fontWeight: number
}

export const useFontStyles = () => {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const getStyle = (desktopSize: string, mobileSize: string, weight: number): FontStyles => ({
		fontSize: isMobile ? mobileSize : desktopSize,
		fontWeight: weight,
	})

	return {
		h1: getStyle('32px', '28px', 700),
		h2: getStyle('28px', '24px', 700),
		h3: getStyle('25px', '22px', 700),
		h4: getStyle('22px', '20px', 700),
		h5: getStyle('20px', '18px', 700),
		body1: getStyle('16px', '14px', 400),
		body2: getStyle('14px', '14px', 400),
		body3: getStyle('14px', '14px', 700),
		caption: getStyle('12px', '10px', 400),
		button: getStyle('20px', '15px', 700),
		subtitle2: getStyle('12px', '10px', 700),
	}
}

import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, useMediaQuery, useTheme } from '@mui/material'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import CustomIcon from '../custom_components/CustomIcon'
import CustomizedButton from '../custom_components/CustomButton'
import { localeKeys } from '../resources/localeConstants'
import { useLocalize } from '../custom_components/CustomHooks'
import { flexStyles } from '../app_features/flexStyles'

interface DeleteAccountDialogProps {
	open: boolean
	onClose: () => void
	onDelete: () => void
}

export const DeleteAccountDialog: React.FC<DeleteAccountDialogProps> = ({ open, onClose, onDelete }) => {
	const localize = useLocalize()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					borderRadius: '20px',
					backgroundColor: 'background.surface',
					backgroundImage: 'none',
					p: '24px',
				},
			}}
		>
			<DialogTitle sx={{ ...flexStyles.flexCenter, pb: 0 }}>
				<CustomIcon name={iconKeys.delete} style={{ width: '55px', height: '55px' }} />
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center', pt: 2 }}>
				<Typography variant={typographyKeys.h2} sx={{ mb: 2 }}>
					{localize(localeKeys.deleteAccount)}
				</Typography>
				<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary', maxWidth: '375px' }}>
					{localize(localeKeys.deletedRecovered)}
				</Typography>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
				<CustomizedButton
					textprops={{
						text: localize(localeKeys.delete),
						color: 'common.white',
					}}
					sxprops={{
						minWidth: isMobile ? '115px' : '180px',
						maxWidth: isMobile ? '115px' : '180px',
						height: isMobile ? '40px' : '50px',
						backgroundColor: 'background.error',
						variant: typographyKeys.button1,
					}}
					onClick={onDelete}
				/>
				<CustomizedButton
					textprops={{
						text: localize(localeKeys.cancel),
						color: 'text.subHeading',
					}}
					sxprops={{
						minWidth: isMobile ? '115px' : '180px',
						maxWidth: isMobile ? '115px' : '180px',
						height: isMobile ? '40px' : '50px',
						backgroundColor: 'background.surface',
						border: '1px solid',
						variant: typographyKeys.button1,
					}}
					onClick={onClose}
				/>
			</DialogActions>
		</Dialog>
	)
}

// PlaylistSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FetchPlaylistRes, MyFriendsResponse } from '../playlistDataModal'
import {
	acceptOrRejectInviteThunk,
	deletePlaylistThunk,
	fetchGamePlaylistThunk,
	unJoinPlaylistThunk,
	removeUpdateGamesThunk,
	fetchFriendsListThunk,
	listMembersThunk,
	addFriendsToPlayListThunk,
	listMyPlaylistsThunk,
	addGameToPlaylistsThunk,
	fetchinvitationDetailsThunk,
	unJoinMembersThunk,
} from './playlistThunk'
import { Friend } from '../friends/Friends'

// Define the shape of the authentication state
interface PlaylistProps {
	activeTab: number
	gamePlaylists: FetchPlaylistRes | null
	friendsData: Friend[] | null
	totalMembersCount: number
	rerenderGames: boolean
	myFriendsList: MyFriendsResponse | null
	myPlaylists: FetchPlaylistRes | null
}

// Initial state for the authentication slice
const initialState: PlaylistProps = {
	activeTab: 0,
	gamePlaylists: null,
	friendsData: null,
	totalMembersCount: 0,
	rerenderGames: false,
	myFriendsList: null,
	myPlaylists: null,
}

export const getGamePlaylist = createAsyncThunk('playlist/FetchGamePlaylist', fetchGamePlaylistThunk)
export const listMembersApi = createAsyncThunk('playlist/listMembers', listMembersThunk)
export const deletePlaylist = createAsyncThunk('playlist/deletePlaylist', deletePlaylistThunk)
export const unJoinPlaylist = createAsyncThunk('playlist/unJoinPlaylist', unJoinPlaylistThunk)
export const acceptOrRejectInvite = createAsyncThunk('playlist/acceptOrRejectInvite', acceptOrRejectInviteThunk)
export const removeUpdateGames = createAsyncThunk('playlist/removeUpdateGames', removeUpdateGamesThunk)

export const fetchMyFriendsList = createAsyncThunk('playlist/listMyFriends', fetchFriendsListThunk)
export const addFriendsToPlaylist = createAsyncThunk('playlist/addFriendsToPlaylist', addFriendsToPlayListThunk)
export const listMyPlaylists = createAsyncThunk('playlist/listMyPlaylists', listMyPlaylistsThunk)
export const addGameToPlaylists = createAsyncThunk('playlist/addAgameToPlaylists', addGameToPlaylistsThunk)
export const fetchinvitationDetails = createAsyncThunk('playlist/fetchinvitationDetails', fetchinvitationDetailsThunk)
export const unJoinMembers = createAsyncThunk('playlist/unJoinMembers', unJoinMembersThunk)
// Authentication slice with reducers and extraReducers for async actions
const playlistSlice = createSlice({
	name: 'playlist',
	initialState,
	reducers: {
		setActiveTab: (state, action) => {
			state.activeTab = action.payload
		},
		setRerenderGames: (state, action) => {
			state.rerenderGames = action.payload
		},
	},

	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder.addCase(getGamePlaylist.fulfilled, (state, action) => {
			state.gamePlaylists = action.payload.data.data
		})

		builder.addCase(listMyPlaylists.fulfilled, (state, action) => {
			state.gamePlaylists = action.payload.data.data
		})

		builder.addCase(fetchMyFriendsList.fulfilled, (state, action) => {
			state.myFriendsList = action.payload.data.data
		})

		builder.addCase(removeUpdateGames.fulfilled, (state) => {
			state.rerenderGames = true
		})

		builder.addCase(listMembersApi.fulfilled, (state, action) => {
			state.friendsData = action.payload.data.data.members.map((member: any) => ({
				id: member._id,
				image: member.inviteeProfilePictureUrl, // You can update this if there's an image field in the actual payload
				name: member.friend,
				displayName: member.inviteeFullName || member.inviterFullName, // Choose invitee or inviter based on your needs
				email: member.inviteeEmailId || member.inviterEmailId, // Same logic for email
				status: member.status.charAt(0).toUpperCase() + member.status.slice(1), // Capitalize status
			}))
			state.totalMembersCount = action.payload.data.data.totalCount
		})
	},
})

export const { setActiveTab, setRerenderGames } = playlistSlice.actions

export default playlistSlice.reducer

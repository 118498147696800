'use client'

import React, { CSSProperties, ChangeEvent, useEffect, useRef, useState } from 'react'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Typography, Checkbox, Box, FormControl, FormControlProps, useTheme } from '@mui/material'
import CustomIcon from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { EmptyFunction } from '../app_features/types'
import { useLocalize } from './CustomHooks'
import { flexStyles } from '../app_features/flexStyles'
import { commonStyles } from '../app_features/commonStyles'

type OptionType = { key: string; label: string; subLabel?: string }

export interface CustomAutoCompleteProps {
	name?: string
	label?: string
	placeholder?: string
	value: OptionType[]
	onChange?: (event: ChangeEvent<unknown>, value: OptionType[]) => void | undefined
	limitTags?: number
	options?: OptionType[]
	multiple?: boolean
	freeSolo?: boolean
	disabled?: boolean
	noOptionsText?: string
	onOpen?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined
	onClose?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined
	onInputChange?: ((event: React.SyntheticEvent<Element, Event>, value: string) => void) | undefined
	notRequired?: boolean
	defaultValue?: string[]
	readOnly?: boolean
	disableWithColor?: boolean
	popupIcon?: string
	onpopUpIconClick?: EmptyFunction
	handleOnDelete?: EmptyFunction
	restrictCharNum?: boolean
	multiDatesReadOnly?: boolean
	sx?: CSSProperties
	isDefaultAllSelected?: boolean
	defaultAllSelectedText?: string
	formControlProps?: FormControlProps
	inputLabel?: string
	padding?: string
}

/**
 * CustomAutoComplete: A customized autocomplete component with extended props for additional features and styling.
 * @param name - The name attribute for the input element.
 * @param label - The label for the autocomplete field.
 * @param placeholder - The placeholder text for the autocomplete input.
 * @param value - The currently selected value(s) as an array of key-label pairs.
 * @param onChange - Callback function invoked when the selected value(s) change.
 * @param limitTags - Maximum number of tags to display when multiple values are selected.
 * @param options - List of options for autocompletion.
 * @param multiple - Flag indicating whether multiple values can be selected.
 * @param freeSolo - Flag indicating whether the user can input values not present in options.
 * @param disabled - Flag indicating whether the autocomplete is disabled.
 * @param onOpen - Callback function invoked when the dropdown menu opens.
 * @param onClose - Callback function invoked when the dropdown menu closes.
 * @param noOptionsText - Text to display when no options match the input value.
 * @param onInputChange - Callback function invoked when the input value changes.
 * @param notRequired - Flag indicating whether the autocomplete is not required.
 * @param readOnly - Flag indicating whether the autocomplete is read-only.
 * @param disableWithColor - Flag indicating whether to disable with color.
 * @param popupIcon - Icon displayed alongside the input.
 * @param onpopUpIconClick - Callback function invoked when the popup icon is clicked.
 * @param handleOnDelete - Callback function invoked when a tag is deleted.
 * @param restrictCharNum - Flag indicating whether to restrict character number.
 * @param multiDatesReadOnly - Flag indicating whether multiple dates are read-only.
 * @param sx - Additional styles for customization using CSSProperties.
 * @param isDefaultAllSelected - Flag indicating whether all options are selected by default.
 * @param defaultAllSelectedText - Text to display when all options are selected by default.
 * @param formControlProps - Props for customizing form control.
 * @param inputLabel - Label for the input field.
 * @returns ReactElement representing the custom autocomplete component.
 */
const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
	name,
	label,
	placeholder,
	value,
	onChange,
	limitTags,
	options = [],
	multiple = true,
	freeSolo = true,
	disabled,
	onOpen,
	onClose,
	noOptionsText,
	onInputChange,
	notRequired,
	readOnly = false,
	popupIcon,
	onpopUpIconClick,
	handleOnDelete,
	disableWithColor = false,
	restrictCharNum = false,
	multiDatesReadOnly = false,
	sx,
	isDefaultAllSelected = false,
	defaultAllSelectedText,
	formControlProps,
	inputLabel,
	padding,
}) => {
	const localize = useLocalize()
	const theme = useTheme()

	const textFieldRef = useRef<HTMLInputElement | null>(null)

	const handleDeleteChip = (event: ChangeEvent<unknown>, index: number) => {
		// Function to handle deletion of a chip (selected option)
		const newValue = value.filter((chip, i) => index !== i)
		onChange?.(event, newValue)
		handleOnDelete?.()
		if (textFieldRef.current) {
			// Programmatically trigger a click event on the text field (autocomplete input)
			const event = new Event('click', { bubbles: true })
			textFieldRef.current.dispatchEvent(event)
		}
	}
	const [isSelectedAllOptions, setIsSelectedAllOptions] = useState<boolean>(false)

	useEffect(() => {
		// Effect to check if all options are selected based on props
		if (defaultAllSelectedText && defaultAllSelectedText?.length > 0 && isDefaultAllSelected) {
			setIsSelectedAllOptions(options.length > 0 && options.length === value?.length)
		}
	}, [defaultAllSelectedText, isDefaultAllSelected, options, value])

	return (
		<FormControl sx={{ width: '100%' }} variant='outlined' {...formControlProps}>
			{inputLabel && (
				<Box sx={{ ...flexStyles.flexRow, gap: '5px', mb: '6px' }}>
					<Typography sx={{ display: 'inline' }} variant={typographyKeys.body2}>
						{inputLabel}
					</Typography>
					<Typography sx={{ display: 'inline', color: 'red.normalmain' }} variant={typographyKeys.body2}>
						{notRequired ? '' : '*'}
					</Typography>
				</Box>
			)}
			<Autocomplete
				onClick={() => {
					onpopUpIconClick?.()
				}}
				ref={textFieldRef}
				multiple={multiple}
				disableCloseOnSelect={multiple}
				id={`tags-filled-${name}`}
				sx={{ minHeight: '46px', ...sx }}
				options={
					isDefaultAllSelected && options.length > 0 && defaultAllSelectedText?.length
						? [{ key: defaultAllSelectedText, label: defaultAllSelectedText }, ...options.map((option) => option)]
						: options.map((option) => option)
				}
				freeSolo={freeSolo}
				getOptionLabel={(option: OptionType | string): string => {
					if (typeof option === 'string') {
						return option ?? ''
					} else {
						return option.label ?? ''
					}
				}}
				onOpen={onOpen}
				onClose={onClose}
				onInputChange={onInputChange}
				readOnly={readOnly}
				renderTags={(value: readonly OptionType[], getTagProps) => {
					if (value && (!isDefaultAllSelected || (isDefaultAllSelected && !(isSelectedAllOptions || options.length === value.length)))) {
						return (
							<Box
								sx={{
									...flexStyles.flexRowAlignCenter,
									overflow: 'auto',
									position: 'absolute',
									mr: '-30px',
									...commonStyles.hideScroll,
									width: '77%',
								}}
							>
								{value
									?.filter((option) => option?.key?.trim() !== '')
									.map((option: OptionType, index: number) => {
										const { key, ...otherProps } = getTagProps({ index })
										return (
											<React.Fragment key={key + '' + index}>
												{readOnly && !multiDatesReadOnly ? (
													<Chip
														key={key + '' + index}
														label={option?.label ?? ''}
														{...otherProps}
														sx={{
															borderRadius: '2px',
															bgcolor: 'blue.lightmain',
															height: '27px',
															...theme.typography.body1,
														}}
													/>
												) : (
													<Chip
														key={key + '' + index}
														label={option?.label ?? ''}
														{...otherProps}
														onDelete={(event: ChangeEvent<unknown>) => handleDeleteChip(event, index)}
														sx={{
															borderRadius: '2px',
															bgcolor: 'blue.lightmain',
															height: '27px',
															...theme.typography.body1,
														}}
													/>
												)}
											</React.Fragment>
										)
									})}
							</Box>
						)
					} else if (isDefaultAllSelected && (isSelectedAllOptions || options.length === value.length)) {
						return (
							<Typography variant={typographyKeys.body1} sx={{ fontWeight: 'bold', font: 'inherit' }}>
								{defaultAllSelectedText}
							</Typography>
						)
					}
				}}
				isOptionEqualToValue={(option, localValue) => {
					if (Array.isArray(localValue)) {
						if (localValue.length > 0) {
							return JSON.stringify(option) === JSON.stringify(value[0])
						}
					} else if (localValue && localValue.key) {
						return JSON.stringify(option) === JSON.stringify(localValue)
					}
					return false
				}}
				renderOption={(props, option, { selected, index }) => {
					const { key, ...otherProps } = props as any
					return (
						<React.Fragment key={index}>
							<li
								key={key + '' + index}
								{...otherProps}
								style={{
									padding: '10px',
									cursor: 'pointer',
								}}
							>
								<Box sx={{ ...flexStyles.flexRowAlignCenter }}>
									{multiple && (
										<Checkbox
											icon={<CustomIcon name={iconKeys.calendar} style={{ width: '22px', height: '22px' }} />}
											checkedIcon={
												<CustomIcon
													name={
														isSelectedAllOptions && isDefaultAllSelected && option?.label === defaultAllSelectedText
															? iconKeys.calendar
															: iconKeys.calendar
													}
													style={{ width: '22px', height: '22px' }}
												/>
											}
											style={{ marginRight: 4 }}
											checked={
												selected ||
												(value?.length > 0 &&
													options?.length > 0 &&
													value?.length === options?.length &&
													option?.key?.trim() === defaultAllSelectedText?.trim())
											}
										/>
									)}
									<Box sx={{ ...flexStyles.flexColumn }}>
										<Typography variant={typographyKeys.body2} sx={{ color: 'text.secondary' }}>
											{option?.key === defaultAllSelectedText ? defaultAllSelectedText : option?.label ?? ''}
										</Typography>
										{option?.subLabel && (
											<Typography variant={typographyKeys.label1} sx={{ color: 'text.tertiary' }}>
												{option?.subLabel ?? ''}
											</Typography>
										)}
									</Box>
								</Box>
							</li>
						</React.Fragment>
					)
				}}
				popupIcon={
					<CustomIcon
						name={popupIcon ?? iconKeys.arrowDown}
						style={{
							width: '24px',
							height: '24px',
							display: readOnly && !onpopUpIconClick ? 'none' : '',
						}}
						onClick={onpopUpIconClick}
					/>
				}
				renderInput={(params) => (
					<TextField
						{...params}
						onClick={onpopUpIconClick}
						label={
							label ? (
								<Box
									sx={{
										...flexStyles.flexRowAlignCenter,
										justifyContent: 'center',
									}}
								>
									<span
										className='span-weight'
										style={{
											color: readOnly && disableWithColor ? 'greyNew.normalmain' : '',
										}}
									>
										{localize(label)}
									</span>
									<Typography variant={typographyKeys.body2} sx={{ ml: '4px', color: 'red.normalmain' }}>
										{notRequired ? '' : '*'}
									</Typography>
								</Box>
							) : null
						}
						placeholder={value?.length !== 0 ? undefined : placeholder}
						sx={{
							'& input::placeholder': {
								...theme?.typography?.body2,
								color: 'text.secondary',
								opacity: 1,
							},
							// marginTop: inputLabelMarginTop,
							'& .MuiInputLabel-root': {
								color: 'common.black',
								'&.Mui-focused, &.MuiInputLabel-shrink': {
									color: 'orange.normalmain',
									marginTop: '0px',
								},
								marginTop: '-3px',
							},
							'& .asterisk': {
								color: 'red.normalmain',
							},
							'& .MuiOutlinedInput-root': {
								p: padding ?? '5.5px !important',
								backgroundColor: readOnly ? 'greyNew.lightborder' : 'transparent',
								'& fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'greyNew.lighthover',
								},
								'&:hover fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'orange.normalmain',
								},
								'&.Mui-focused fieldset': {
									borderWidth: '1px',
									borderColor: readOnly ? 'greyNew.lightactive' : disabled ? 'greyNew.lighthover' : 'orange.normalmain',
								},
							},
							'& .MuiAutocomplete-clearIndicator': {
								display: 'none',
							},
						}}
						onKeyDown={(event) => {
							// Restrict characters and numbers
							if (restrictCharNum) {
								if (!/^[a-zA-Z\s]*$/.test(event.key)) {
									event.preventDefault()
								}
							}
						}}
					/>
				)}
				value={value?.length === 0 ? (multiple ? [] : null) : multiple ? value : value?.[0]}
				onChange={(event, newValue) => {
					const ArrayValue = (newValue ? (Array.isArray(newValue) ? newValue : [newValue]) : []) as { key: string; label: string }[]
					if (
						((ArrayValue?.some((value) => value?.key?.trim() === defaultAllSelectedText?.trim()) && newValue && ArrayValue.length > 0) ||
							(options.length > 0 && options?.length === ArrayValue?.length)) &&
						isDefaultAllSelected
					) {
						if (!isSelectedAllOptions) {
							onChange?.(event, options)
							setIsSelectedAllOptions(true)
						} else {
							onChange?.(event, [])
							setIsSelectedAllOptions(false)
						}
					} else {
						onChange?.(event, ArrayValue)
					}
				}}
				ListboxProps={{ style: { maxHeight: 300 } }}
				limitTags={limitTags ?? 3}
				disabled={disabled}
				noOptionsText={
					<>
						<Typography variant={typographyKeys.body2} sx={{ color: 'text.secondary' }}>
							{localize(noOptionsText)}
						</Typography>
					</>
				}
			/>
		</FormControl>
	)
}

export default CustomAutoComplete

import React, { Suspense, useEffect, useState } from 'react'
import { Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material'
import { LeaderboardComponent } from './LeaderBoardComponent'
import CustomIcon from '../../custom_components/CustomIcon'
import { iconKeys, typographyKeys } from '../../resources/resourceConstants'
import { flexStyles } from '../../app_features/flexStyles'
import { useAppDispatch, useAppSelector, useLocalize } from '../../custom_components/CustomHooks'
import { localeKeys } from '../../resources/localeConstants'
import TabButtonGroup from './TabButtonGroup'
import { leaderBoardDialogApi } from '../games/single_game/singleGame_thunk/singleGameSlice'
import { LeaderBoardRequest } from '../games/single_game/singleGame_thunk/signleGameDatamodal'
import UserRankDetails from './UserRankDetails'
import LeaderBoardListTable from '../games/single_game/instructions_leaderBoard/LeaderBoardListTable'
import { TableColumnProps } from '../../custom_components/CustomTableHeader'
import { leaderboardHeaderData } from '../games/single_game/instructions_leaderBoard/constants'
import GamesListRight from './GamesListRight'
import { getUserFromLocalStorage } from '../../utils/localStorage'
import { UserProfile } from '../home_thunk/homeDatamodal'
import { fetchGames } from '../games/games_thunk/gamesSlice'
import CustomPagination from '../../custom_components/CustomPagination'
import ZerothScreen from '../../custom_components/ZerothScreen'
import GameListSelect from './GameSelect'
import Loader from '../../custom_components/Loader'
import ProfileDialog from '../ProfileDialog'
import { AnyObject } from '../../utils/utils'

const LeaderBoard = () => {
	const userData = getUserFromLocalStorage() as UserProfile
	const localize = useLocalize()
	const isSmallDevice = useMediaQuery('(max-width: 769px)')
	const isLg = useMediaQuery('(max-width: 1025px)')
	const theme = useTheme()
	const isSm = useMediaQuery(theme.breakpoints.down('sm'))

	const { leaderBoardDialogData, leaderBoardDialogDataTotal, leaderBoardYouDetails } = useAppSelector((state) => state.singleGame)
	const [columns, setColumns] = useState<TableColumnProps[]>(leaderboardHeaderData)
	const dispatch = useAppDispatch()
	const [games, setGames] = useState<{ _id: string; gameName: string }[] | null>(null)
	const [filter, setFilter] = useState<string>('Daily')
	const [currentGameId, setCurrentGameId] = useState<string>('')
	const [currentPage, setCurrentPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [profileOpen, setProfileOpen] = useState(userData ? !userData?.profileCompleted : false)

	const svgAvatar =
		'data:image/svg+xml;base64,' +
		btoa(`
		<svg viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
			<path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
		</svg>
	`)

	const players = leaderBoardDialogData?.leaderboard?.map((player) => ({
		id: player._id,
		name: player.user.displayName,
		score: player.score,
		rank: player.rank,
		avatar: player.user.profilePictureUrl || svgAvatar,

		// Placeholder avatar URL
		details: {
			// gender: player?.user?.gender === '1' ? 'Male' : player?.user?.gender === '2' ? 'Female' : 'Other',
			gender:
				Number(player.user.gender) === 1
					? 'Male'
					: Number(player.user.gender) === 2
					? 'Female'
					: Number(player.user.gender) === 3
					? 'Others'
					: 'NA',
			country: player.user.country || '',
			state: player.user.state || '',
			city: player.user.city || '',
		},
	}))

	const leaderBoardListData = leaderBoardDialogData?.leaderboard?.map((player) => ({
		_id: player._id,
		rank: player.rank.toString(),
		name: player.user.displayName,
		gender: player?.user?.gender,
		profession: player.user.profession || '',
		country: player.user.country || '',
		state: player.user.state || '',
		city: player.user.city || '',
		score: player.score.toString(),
	}))

	const handleSelectGame = (id: string) => {
		setCurrentGameId(id)
	}

	const leaderBoardDialogApiCall = async (gameId: string) => {
		const body: LeaderBoardRequest = {
			game: gameId,
			filter: filter === 'Daily' ? { dates: 1 } : filter === 'Weekly' ? { dates: 2 } : filter === 'Monthly' ? { dates: 3 } : { dates: 4 },
			page: currentPage,
			limit: rowsPerPage,
		}
		await dispatch(leaderBoardDialogApi(body))
	}

	const getGames = async () => {
		const body = { user: userData._id }
		await dispatch(fetchGames(body)).then((res) => {
			if (res && res.meta.requestStatus === 'fulfilled') {
				const games = res.payload.data.data.map((game: AnyObject) => ({ _id: game._id, gameName: game.gameName }))
				setGames(games)
				leaderBoardDialogApiCall(res.payload.data.data[0]._id)
				setCurrentGameId(res.payload.data.data[0]._id)
			}
		})
	}

	useEffect(() => {
		if (userData !== null) {
			getGames()
		}
	}, [])

	useEffect(() => {
		if (currentGameId !== '') {
			leaderBoardDialogApiCall(currentGameId)
		}
	}, [currentGameId, filter, currentPage, rowsPerPage])

	return (
		<Box sx={{ p: isSm ? '0px 20px 0px 20px' : '20px 20px 0px 20px' }}>
			<Grid container spacing={isSmallDevice ? 0 : 3}>
				{/* Adjust columns based on screen size */}
				<Grid
					item
					xs={12}
					xl={isLg ? 10 : 9}
					md={isSmallDevice ? 12 : 9}
					lg={isSmallDevice ? 12 : 9}
					sx={{
						maxHeight: isSmallDevice ? '' : 'calc(100vh - 120px)',
						overflow: isSmallDevice ? '' : 'auto',
					}}
				>
					<Box sx={{ ...flexStyles.flexRowCenter, gap: '8px', pb: '10px' }}>
						<CustomIcon name={iconKeys.seeLeaderboard} style={{ width: '32px', height: '32px' }} svgStyle='width: 32px; height: 32px' />
						<Typography variant={typographyKeys.h1} color='text.defaultHeading'>
							{localize(localeKeys.leaderboard)}
						</Typography>
					</Box>
					{!isSmallDevice && (
						<Typography variant={typographyKeys.h3} color='text.defaultHeading' sx={{ textAlign: 'center' }}>
							{games?.filter((game) => game?._id === currentGameId)[0].gameName}
						</Typography>
					)}

					{isSmallDevice && (
						<Box sx={{ ...flexStyles.flexCenter }}>
							<GameListSelect games={games} onSelectGame={handleSelectGame} selectedGameId={currentGameId} />
						</Box>
					)}
					<Box sx={{ ...flexStyles.flexRowCenterSpaceEvenly, p: '0px' }}>
						{!isSm && <CustomIcon name={iconKeys.frameLeft} />}
						<Box sx={{ ...flexStyles.flexColumn }}>
							<LeaderboardComponent players={players || []} currentPage={currentPage} />
							<Box sx={{ mt: isSmallDevice ? (isSm ? '-40px' : '-20px') : '10px' }}>
								<TabButtonGroup
									labels={['Daily', 'Weekly', 'Monthly', 'Yearly']}
									onTabClick={(label) => {
										setCurrentPage(1)
										setFilter(label)
									}}
								/>
							</Box>
						</Box>
						{!isSm && <CustomIcon name={iconKeys.frameRight} />}
					</Box>

					<Box sx={{ mt: '20px', ...flexStyles.flexCenter }}>
						<UserRankDetails
							rank={leaderBoardYouDetails?.rank?.toString() ?? ''}
							score={leaderBoardYouDetails?.score?.toString() ?? ''}
							profileUrl={leaderBoardYouDetails?.profilePictureUrl?.toString() ?? ''}
						/>
					</Box>
					<Box sx={{ mt: '22px', pr: isSm ? '' : '10px' }}>
						<LeaderBoardListTable
							containerHeight={430}
							data={currentPage === 1 ? leaderBoardListData?.slice(3) || [] : leaderBoardListData || []}
							columns={columns}
							setColumns={setColumns}
							handleRowClick={() => {
								//'Row clicked'
							}}
						/>
						{leaderBoardListData && (currentPage === 1 ? leaderBoardListData.slice(3).length <= 0 : leaderBoardListData.length <= 0) ? (
							<>
								<ZerothScreen
									icon={iconKeys.leaderBoardZeroth2}
									imageWidth={'100px'}
									imageHeight={'100px'}
									tilte={localeKeys.noRanksAt}
									subTitle={localeKeys.startPlayingNowToEarn}
								/>
							</>
						) : null}
						{leaderBoardListData && leaderBoardDialogDataTotal >= 10 && (
							<CustomPagination
								currentPage={currentPage}
								totalPages={Math.ceil(leaderBoardDialogDataTotal / rowsPerPage)}
								rowsPerPage={rowsPerPage}
								totalCount={leaderBoardDialogDataTotal || 0}
								onPageChange={(newPage) => setCurrentPage(newPage)}
								onRowsPerPageChange={(newRows) => setRowsPerPage(newRows)}
								positionNotRequired={true}
								rowsPerPageOptions={[10, 40]}
							/>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} xl={isLg ? 2 : 3} md={isSmallDevice ? 12 : 3} lg={isSmallDevice ? 12 : 3}>
					{!isSmallDevice && (
						<Box>
							<GamesListRight games={games || []} onSelectGame={handleSelectGame} />
						</Box>
					)}
				</Grid>
			</Grid>
			<Suspense fallback={<Loader />}>
				<ProfileDialog
					open={profileOpen}
					handleClose={() => {
						if (userData?.profileCompleted) setProfileOpen(false)
						// dispatch(resetProfileForm())
					}}
				/>
			</Suspense>
		</Box>
	)
}

export default LeaderBoard

import React, { CSSProperties } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import CustomIcon from './CustomIcon'
import { useLocalize } from './CustomHooks'

interface CustomDialogProps {
	handleClose: () => void
	open: boolean
	title?: string
	children: React.ReactNode
	width?: string
	height?: string
	sx?: CSSProperties
	maxWidth?: string
	hideCloseIcon?: boolean
	titileIcon?: boolean
}

const CustomDialog = (props: CustomDialogProps) => {
	const { open, title, titileIcon, handleClose, children, hideCloseIcon = false, width = '80%', maxWidth = '100%', height = '100%', sx } = props
	const localize = useLocalize()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Dialog
			open={open}
			PaperProps={{
				sx: {
					borderRadius: '20px',
					minWidth: width,
					maxHeight: height,
					// height: height,
					backgroundColor: 'background.surface',
					backgroundImage: 'none',
					...sx,
					maxWidth: maxWidth,
				},
			}}
			sx={{ backdropFilter: 'blur(3px)' }}
		>
			<DialogTitle>
				<Typography sx={{ display: title ? 'block' : 'none' }} variant={typographyKeys.h3}>
					{localize(title)}
				</Typography>
				{titileIcon && (
					<CustomIcon
						name={iconKeys.getFitNowIcon}
						style={{ width: '200px', height: '55px', position: 'absolute', left: 12, top: 4 }}
						svgStyle='width: 200px; height: 55px'
					/>
				)}

				{!hideCloseIcon && ( // Conditionally render the close icon
					<IconButton aria-label='close' onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 12 }}>
						<CustomIcon name={iconKeys.closeCircle} style={{ width: '24px', height: '24px' }} />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent dividers={!!title} sx={{ p: isMobile ? '16px 12px' : '16px 28px' }}>
				{children}
			</DialogContent>
		</Dialog>
	)
}

export default CustomDialog

import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { getAuthTypeFromLocalStorage, getTokenFromLocalStorage, getUserFromLocalStorage } from '../utils/localStorage'
import { useAppDispatch } from './CustomHooks'
import { fetchProfile } from '../home/home_thunk/homeSlice'
import { routePaths } from '../routes/routeKeys'
import Loader from '../custom_components/Loader'
import { getMiscellaneous } from '../app_features/current_user/currentUserSlice'
import { UserProfile } from '../home/home_thunk/homeDatamodal'

interface RouteGuardProps {
	children: React.ReactNode
}

const RouteGuard: React.FC<RouteGuardProps> = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true)
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useAppDispatch()

	useEffect(() => {
		const checkAuth = async () => {
			const accessToken = getTokenFromLocalStorage()
			const accessType = getAuthTypeFromLocalStorage()
			const user: UserProfile | null = getUserFromLocalStorage()

			if (accessToken && accessType && user && user.isActive) {
				await dispatch(fetchProfile())
				await dispatch(getMiscellaneous())

				if (location.pathname === routePaths.home || location.pathname === '/' || location.pathname === routePaths.aboutUs) {
					navigate(routePaths.games, { replace: true })
				}
			} else {
				if (location.pathname !== routePaths.aboutUs && location.pathname !== routePaths.home && location.pathname !== '/') {
					navigate(routePaths.home, { replace: true })
				}
			}

			setIsLoading(false)
		}

		checkAuth()
	}, [dispatch, location.pathname, navigate])

	if (isLoading) {
		return <Loader />
	}

	return <>{children}</>
}

export default RouteGuard

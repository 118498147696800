import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Box, InputBaseComponentProps, Paper, TypographyProps, Dialog } from '@mui/material'
import dayjs from 'dayjs'
import { localeKeys } from '../resources/localeConstants'
import { iconKeys } from '../resources/resourceConstants'
import CustomAutoComplete, { CustomAutoCompleteProps } from './CustomAutoComplete'
import { useLocalize } from './CustomHooks'
import ReusableDatePicker from './ReusableDatePicker'
import { flexStyles } from '../app_features/flexStyles'
import CustomizedButton from './CustomButton'
import CustomDateField from './CustomDateField'

export interface InlineDatePickerProps {
	onDateRangeChange?: (startDate: Date | null, endDate: Date | null) => void
	startDate?: Date | null
	endDate?: Date | null
	dateRange?: boolean
	setApplyButtonClicked?: (value: boolean) => void
	label?: string
	value?: Date | null
	onSingleDateChange?: (date: Date | null) => void
	isDOB?: boolean
	propSx?: InputBaseComponentProps & { height?: string; maxLength?: string }
	typoProps?: TypographyProps
	isRequired?: boolean
	readOnly?: boolean
	multiDates?: boolean
	autoCompleteProps?: CustomAutoCompleteProps
	minDate?: Date | null
	maxDate?: Date | null
	disabled?: boolean
	disableWithColor?: boolean
	helperText?: string
	helperTextColor?: string
	error?: boolean
	isWeekSelection?: boolean
	isMonthSelection?: boolean
	placeholder?: string
	isSingleDateFieldRequired?: boolean
	dialogOpen?: boolean
	setDialogOpen?: (value: React.SetStateAction<boolean>) => void
	inputlabel?: string
}

/**
 * CustomDatePicker: A component that provides a date picker with various functionalities based on the provided props,it could also act as Single Date picker or can act as Range date picker
 * @param onDateRangeChange - Function triggered when a date range is changed.
 * @param startDate - The start date in the range.
 * @param endDate - The end date in the range.
 * @param dateRange - A boolean indicating whether the date picker allows a range of dates.
 * @param setApplyButtonClicked - Function to handle click on the Apply button.
 * @param title - The label/title for the date picker.
 * @param value - The selected date or range of dates.
 * @param onSingleDateChange - Function triggered on selection of a single date.
 * @param propSx - CSS properties width and Height.
 * @param isRequired - A boolean indicating whether the field is required or not.
 * @returns ReactElement
 */
const CustomDatePicker = ({
	onDateRangeChange,
	startDate = null,
	endDate = null,
	dateRange = false,
	isRequired = false,
	label,
	value = null,
	onSingleDateChange,
	propSx,
	readOnly,
	multiDates = false,
	disabled = false,
	helperText,
	helperTextColor,
	error = false,
	placeholder,
	isWeekSelection = false,
	isMonthSelection = false,
	isSingleDateFieldRequired = false,
	dialogOpen = false,
	setDialogOpen,
	inputlabel,
	...props
}: InlineDatePickerProps): ReactElement => {
	const [open, setOpen] = useState<boolean>(false)
	const localize = useLocalize()

	const handleClick = () => {
		if (!readOnly && !disabled) setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
		setDialogOpen?.(false)
	}
	const [highlightedDates, setHighlightedDates] = useState<Date[]>([])
	// const [highlightedDatesLocal, setHighlightedDatesLocal] = useState<Date[]>([])

	const handleMultiDateChange = (date: Date) => {
		// Find if the date already exists in the highlightedDates array
		const existingIndex = highlightedDates.findIndex((highlightedDate) => dayjs(highlightedDate).isSame(dayjs(date), 'day'))

		if (existingIndex !== -1) {
			// If the date exists, remove it from the highlightedDates array
			const newHighlightedDates = [...highlightedDates]
			newHighlightedDates.splice(existingIndex, 1)
			setHighlightedDates(newHighlightedDates)
		} else {
			// If the date doesn't exist, add it to the highlightedDates array
			setHighlightedDates([...highlightedDates, date])
		}
	}

	// Convert array of Date objects to array of formatted date strings
	const DateToString = (dates: Date[]): string[] => {
		return dates.map((date) => dayjs(date).format('DD MMM YYYY'))
	}

	// Convert array of formatted date strings to array of Date objects
	const StringToDate = (dates: string[] | undefined): Date[] => {
		if (!dates) return []
		return dates.map((date) => dayjs(date, 'DD MMM YYYY').toDate())
	}
	// useEffect to set the highlighted dates when the component mounts or the 'open' state changes
	useEffect(() => {
		// Convert the array of date labels from props to actual Date objects and set the highlightedDates state
		setHighlightedDates(StringToDate(props.autoCompleteProps?.value.map((date) => date.label)))
	}, [open])

	// useEffect to update the 'open' state based on 'dialogOpen' and 'isSingleDateFieldRequired' props
	useEffect(() => {
		if (!isSingleDateFieldRequired) {
			// Update the 'open' state if 'isSingleDateFieldRequired' is false
			setOpen(dialogOpen)
		}
	}, [dialogOpen, isSingleDateFieldRequired])

	// Function to create ButtonProps with specified text and onClick handler
	const ButtonProps = (text: string, onClick: (event: ChangeEvent<unknown>) => void) => ({
		textprops: {
			text,
			fontSize: '15px',
			color: 'orange.normalmain',
		},
		sxprops: {
			backgroundColor: 'common.white',
			p: '0px 0px 0px 5px',
			minWidth: '40px',
			height: '30px',
			border: 'none',
		},
		onClick,
		disableElevation: true,
	})

	const handleDoneClick = (event: ChangeEvent<unknown>) => {
		// If props and autoCompleteProps are defined, call the onChange function
		props?.autoCompleteProps?.onChange?.(
			event,
			// Convert the highlightedDates array to strings and map them to objects with 'key' and 'label' properties
			DateToString(highlightedDates)?.map((date) => ({
				key: date,
				label: date,
			}))
		)

		// Call the handleClose function if it's defined
		handleClose?.()

		// Reset the highlightedDates state to an empty array
		setHighlightedDates([])
	}

	return (
		<>
			{dateRange || isMonthSelection || isWeekSelection ? (
				<Dialog open={open} PaperProps={{ sx: { borderRadius: '20px', boxShadow: '#AAAAAA08' } }} onClose={handleClose}>
					<Paper sx={{ p: '5px' }}>
						<Box
							sx={{
								backgroundColor: 'common.white',
							}}
						>
							<ReusableDatePicker
								dateRange={dateRange}
								onDateRangeChange={onDateRangeChange}
								startDate={startDate}
								endDate={endDate}
								minDate={props.minDate}
								maxDate={props.maxDate}
								handleClose={handleClose}
								isMonthSelection={isMonthSelection}
								isWeekSelection={isWeekSelection}
							/>
						</Box>
					</Paper>
				</Dialog>
			) : (
				<>
					{multiDates ? (
						<>
							<CustomAutoComplete
								label={localize(localeKeys.aboutUs)}
								value={[]}
								popupIcon={iconKeys.calendar}
								onpopUpIconClick={() => setOpen(true)}
								multiple={true}
								readOnly={true}
								freeSolo={false}
								multiDatesReadOnly={multiDates}
								{...props.autoCompleteProps}
							/>
							<Dialog
								open={open && multiDates}
								PaperProps={{
									sx: { borderRadius: '20px', boxShadow: '#AAAAAA08' },
								}}
								onClose={handleClose}
							>
								<Paper sx={{ p: '5px' }}>
									<Box
										sx={{
											backgroundColor: 'common.white',
										}}
									>
										<ReusableDatePicker
											dateRange={false}
											multiDates={true}
											highlightedDates={highlightedDates}
											handleMultiDateChange={handleMultiDateChange}
											minDate={props.minDate}
											maxDate={props.maxDate}
										/>
										<Box sx={{ p: '0px 10px 10px 10px', width: '100%' }}>
											<Box sx={{ ...flexStyles.flexRowSpaceBetween }}>
												<CustomizedButton {...ButtonProps(localeKeys.city, handleClose)} />
												<Box sx={{ ...flexStyles.flexRowCenter }} gap={'15px'}>
													<CustomizedButton {...ButtonProps(localeKeys.aboutUs, () => setHighlightedDates([]))} />
													<CustomizedButton {...ButtonProps(localeKeys.aboutUs, handleDoneClick)} />
												</Box>
											</Box>
										</Box>
									</Box>
								</Paper>
							</Dialog>
						</>
					) : (
						<>
							{isSingleDateFieldRequired ? (
								<CustomDateField
									handleClick={handleClick}
									handleClose={handleClose}
									value={value}
									isRequired={isRequired}
									label={label}
									onSingleDateChange={onSingleDateChange}
									propSx={propSx}
									readOnly={readOnly}
									disabled={disabled}
									helperText={helperText}
									helperTextColor={helperTextColor}
									error={error}
									placeholder={placeholder}
									disableWithColor={props.disableWithColor}
									inputLabel={inputlabel}
								/>
							) : null}
							<Dialog onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: '20px' } }}>
								{/* <Paper> */}
								<ReusableDatePicker
									dateRange={false}
									value={dayjs(value).isValid() ? value : null}
									onSingleDateChange={onSingleDateChange}
									handleClose={handleClose}
									minDate={props.minDate}
									disabled={disabled}
									maxDate={props.maxDate}
								/>
								{/* </Paper> */}
							</Dialog>
						</>
					)}
				</>
			)}
		</>
	)
}

export default CustomDatePicker

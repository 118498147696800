import React, { useEffect, useState } from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { Country, State, City } from 'country-state-city'
import { flexStyles } from '../app_features/flexStyles'
import CustomSingleSelect, { Option } from '../custom_components/CustomSingleSelect'
import CustomIcon from '../custom_components/CustomIcon'
import { iconKeys } from '../resources/resourceConstants'
import { useLocalize } from '../custom_components/CustomHooks'
import { localeKeys } from '../resources/localeConstants'
import CustomDatePicker from '../custom_components/CustomDatePicker'
import { ProfileFormModal } from './home_thunk/homeDatamodal'
import CustomPhoneField from '../custom_components/CustomPhoneField'
import CustomTextfield from '../custom_components/CustomTextfield'

interface ProfileDialogFormProps {
	data: ProfileFormModal
	handleChange: (key: keyof ProfileFormModal, value: Option | string | Date | null) => void
	content: React.ReactNode
}

export default function ProfileDialogForm({ data, handleChange, content }: ProfileDialogFormProps) {
	const localize = useLocalize()
	const theme = useTheme()
	const isTab = useMediaQuery(theme.breakpoints.down('ml'))

	const [countryOptions, setCountryOptions] = useState<Option[]>([])
	const [stateOptions, setStateOptions] = useState<Option[]>([])
	const [cityOptions, setCityOptions] = useState<Option[]>([])

	useEffect(() => {
		const countries = Country.getAllCountries().map((country) => ({
			id: country.isoCode,
			label: country.name,
		}))
		setCountryOptions(countries)
	}, [])

	useEffect(() => {
		if (data.country) {
			const states = State.getStatesOfCountry(data.country.id).map((state) => ({
				id: state.isoCode,
				label: state.name,
			}))
			setStateOptions(states)
		} else {
			setStateOptions([])
		}
	}, [data.country])

	useEffect(() => {
		if (data.country && data.state) {
			const cities = City.getCitiesOfState(data.country.id, data.state.id).map((city) => ({
				id: city.name,
				label: city.name,
			}))
			setCityOptions(cities)
		} else {
			setCityOptions([])
		}
	}, [data.country, data.state])

	const handleSelectChange = (key: keyof ProfileFormModal, value: Option | null) => {
		// Update the state for the selected city
		handleChange(key, value)
		if (key === 'country') {
			handleChange('state', null)
			handleChange('city', null)
		} else if (key === 'state') {
			handleChange('city', null)
		}
	}

	return (
		<Box sx={{ ...flexStyles.flexColumn, gap: '16px', width: '100%' }}>
			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomTextfield
						inputLabel={localize(localeKeys.fullName)}
						textFieldProps={{
							id: 'fullName',
							name: 'fullName',
							placeholder: localize(localeKeys.enterFullName),
							value: data.fullName,
							onChange: (e) => handleChange('fullName', e.target.value),
						}}
						icon={<CustomIcon name={iconKeys.profile} style={{ width: '16px', height: '16px' }} svgStyle='width: 16px; height: 16px' />}
						propSx={{ height: '44px' }}
					/>
				</Box>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomTextfield
						inputLabel={localize(localeKeys.displayName)}
						textFieldProps={{
							id: 'displayName',
							name: 'displayName',
							placeholder: localize(localeKeys.enterDisplayName),
							value: data.displayName,
							onChange: (e) => handleChange('displayName', e.target.value),
						}}
						icon={<CustomIcon name={iconKeys.profile} style={{ width: '16px', height: '16px' }} svgStyle='width: 16px; height: 16px' />}
						propSx={{ height: '44px' }}
					/>
				</Box>
			</Box>

			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomTextfield
						inputLabel={localize(localeKeys.emailId)}
						textFieldProps={{
							id: 'emailId',
							name: 'emailId',
							placeholder: localize(localeKeys.enterEmailId),
							value: data.emailId,
							onChange: (e) => handleChange('emailId', e.target.value),
							readOnly: true,
						}}
						icon={<CustomIcon name={iconKeys.email} style={{ width: '16px', height: '16px' }} svgStyle='width: 16px; height: 16px' />}
						propSx={{ height: '44px' }}
					/>
				</Box>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomPhoneField label={localeKeys.mobileNumber} value={data.phone} onPhoneChange={(value) => handleChange('phone', value)} />
				</Box>
			</Box>

			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomDatePicker
						inputlabel={localize(localeKeys.dateOfBirth)}
						value={data.dob ? new Date(data.dob) : null}
						isSingleDateFieldRequired={true}
						onSingleDateChange={(date) => handleChange('dob', date)}
						propSx={{ height: '44px' }}
						maxDate={new Date()}
					/>
				</Box>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomSingleSelect
						label={localize(localeKeys.gender)}
						name='gender'
						placeholder={localize(localeKeys.selectGender)}
						options={[
							{ id: '1', label: 'Male' },
							{ id: '2', label: 'Female' },
							{ id: '3', label: 'Other' },
						]}
						startIcon={<CustomIcon name={iconKeys.gender} style={{ width: '16px', height: '16px' }} />}
						onSelect={(option) => handleChange('gender', option)}
						value={data.gender}
						disableTyping
					/>
				</Box>
			</Box>

			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomSingleSelect
						label={localize(localeKeys.country)}
						name='country'
						placeholder={localize(localeKeys.selectCountry)}
						options={countryOptions}
						startIcon={<CustomIcon name={iconKeys.location} style={{ width: '16px', height: '16px' }} />}
						onSelect={(option) => handleSelectChange('country', option)}
						value={data.country}
					/>
				</Box>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomSingleSelect
						label={localize(localeKeys.state)}
						name='state'
						placeholder={localize(localeKeys.selectState)}
						options={stateOptions}
						startIcon={<CustomIcon name={iconKeys.location} style={{ width: '16px', height: '16px' }} />}
						onSelect={(option) => handleSelectChange('state', option)}
						value={data.state}
					/>
				</Box>
			</Box>

			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomSingleSelect
						label={localize(localeKeys.city)}
						name='city'
						placeholder={localize(localeKeys.selectCity)}
						options={cityOptions}
						startIcon={<CustomIcon name={iconKeys.location} style={{ width: '16px', height: '16px' }} />}
						onSelect={(option) => handleSelectChange('city', option)}
						value={data.city}
						freesolo={true}
					/>
				</Box>
				<Box sx={{ width: isTab ? '100%' : '50%' }}>
					<CustomTextfield
						inputLabel={localize(localeKeys.profession)}
						textFieldProps={{
							id: 'profession',
							name: 'profession',
							placeholder: localize(localeKeys.enterYourProfession),
							value: data.profession,
							onChange: (e) => handleChange('profession', e.target.value),
						}}
						icon={<CustomIcon name={iconKeys.profession} style={{ width: '16px', height: '16px' }} />}
						propSx={{ height: '44px' }}
					/>
				</Box>
			</Box>

			<Box sx={{ ...(isTab ? flexStyles.flexColumn : flexStyles.flexRow), gap: '12px' }}>{content}</Box>
		</Box>
	)
}

/**
 * [colors description]
 * each color has separate object,
 * and text color has one separate object (any new color for text add in text object)
 * [color].normal.main has the main color
 * and [color].light has different light variants of main color
 * [color].dark has different dark variants of main color
 * some colors includes darker variant too
 *
 * note : (define colors in their respective objects if the color is new define it's own object)
 * @var {[type]}
 */
export const colors = {
	white: '#FFFFFF',
	black: '#0b091c',
	text: {
		defaultHeading: '#111827',
		primary: '#4B5563', // mentioned subheading in figma but changed to primary due to mui has default text primary
		disable: '#9ca3af',
		subHeading: '#84cc16', // mentioned primary in figma
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		special: '#EAB308',
	},
	background: {
		backgroundMain: '#F8FAFC',
		surface: '#ffffff',
		primary: '#84cc16',
		primaryLight: '#f7fee7',
		primaryHover: '#65a30d',
		secondary: '#f1f5f9',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		topNavigation: '#ffffff4d',
		blurBg: 'rgba(255, 255, 255, 0.75)',
		dropwdownBg: '#f3f4f6',
		bgLight: '#f7fee7',
		blue: '#3B82F6',
		purple: '#A855F7',
		emerald: '#10B981',
		yellow: '#EAB308',
		greenBG: '#DCFCE7',
	},
	icon: {
		main: '#64748b',
		active: '#1e293b',
		primary: '#84cc16',
		disable: '#9ca3af',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
	},
	border: {
		main: '#e2e8f0',
		active: '#94a3b8',
		primary: '#84cc16',
		disable: '#f3f4f6',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		lightBorder: '#f1f5f9',
	},
	gradient: {
		purple1: '#6F46A9',
		purple2: '#6F3CB8',
		yellowish1: '#D9F99D',
		yellowish2: '#F1FFD1',
		yellowishGradient: 'linear-gradient(90deg, #D9F99D 0%, #F1FFD1 100%)',
	},
	rgbaColor: {
		blue: 'rgba(59, 130, 246, 0.5)',
		yellow: 'rgba(234, 179, 8, 0.5)',
	},
}
export const darkColors = {
	white: '#FFFFFF',
	black: '#0b091c',
	text: {
		defaultHeading: '#f8fafc',
		primary: '#d1d5db', // mentioned subheading in figma but changed to primary due to mui has default text primary
		subHeading: '#84cc16', // mentioned primary in figma
		disable: '#6b7280',
		default: '#84cc16',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		special: '#EAB308', // text/yellow in figma
	},
	background: {
		backgroundMain: '#020617',
		surface: '#111827',
		primary: '#84cc16',
		primaryLight: '#1a2e05',
		primaryHover: '#a3e635',
		secondary: '#1e293b',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		topNavigation: '#0307124d',
		blurBg: 'rgba(3, 7, 18, 0.75)',
		dropwdownBg: '#1f2937',
		bgLight: '#F7FEE7',
		blue: '#3B82F6',
		purple: '#A855F7',
		emerald: '#10B981',
		yellow: '#EAB308',
		greenBG: '#052E16',
	},
	icon: {
		main: '#64748b',
		active: '#cbd5e1',
		primary: '#84cc16',
		disable: '#4b5563',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
	},
	border: {
		main: '#334155',
		active: '#475569',
		primary: '#84cc16',
		disable: '#111827',
		warning: '#f97316',
		success: '#22c55e',
		error: '#ef4444',
		lightBorder: '#1E293B',
	},

	gradient: {
		purple1: '#6F46A9',
		purple2: '#6F3CB8',
		yellowish1: '#D9F99D',
		yellowish2: '#F1FFD1',
		yellowishGradient: 'linear-gradient(90deg, #D9F99D 0%, #F1FFD1 100%)',
	},
	rgbaColor: {
		blue: 'rgba(59, 130, 246, 0.5)',
		yellow: 'rgba(234, 179, 8, 0.5)',
	},
}

import React, { useEffect, useState, useMemo, useRef } from 'react'
import CustomDialog from '../custom_components/CustomDialog'
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import CustomIcon from '../custom_components/CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { flexStyles } from '../app_features/flexStyles'
import { localeKeys } from '../resources/localeConstants'
import { useAppDispatch, useAppSelector, useLocalize } from '../custom_components/CustomHooks'
import ProfileDialogForm from './ProfileDialogForm'
import CustomizedButton from '../custom_components/CustomButton'
import { Option } from '../custom_components/CustomSingleSelect'
import {
	completeUserProfileApi,
	deleteUser,
	fetchProfile,
	sendReautheticateOtp,
	setProfileData,
	verifyReautheticateOtp,
} from './home_thunk/homeSlice'
import { CompleteProfileRequest, UserProfile } from './home_thunk/homeDatamodal'
import {
	getUserFromLocalStorage,
	removeAuthTypeFromLocalStorage,
	removeTokenFromLocalStorage,
	removeUserFromLocalStorage,
} from '../utils/localStorage'
import { Country, State, City } from 'country-state-city'
import { DeleteAccountDialog } from './DeleteAccountDialog'
import VerifyAccount from './VerifyAccount'
import { VerifyOtpRequest } from './signUp/signup_thunk/signupDatamodal'
import { logoutApi, uploadImageToS3, uploadProfilePic } from '../app_features/current_user/currentUserSlice'
import { routePaths } from '../routes/routeKeys'
import { HexAvatar } from '../custom_components/CustomHexaAvatar'

interface ProfileDialogProps {
	open: boolean
	handleClose: () => void
}

export default function ProfileDialog({ open, handleClose }: ProfileDialogProps) {
	const theme = useTheme()
	const localize = useLocalize()
	const isTab = useMediaQuery(theme.breakpoints.down('ml'))
	const { profileForm, userData } = useAppSelector((state) => state.homeAuth)
	const dispatch = useAppDispatch()
	const [user, setUser] = useState<UserProfile | null>(null)
	const [initialFormData, setInitialFormData] = useState<typeof profileForm | null>(null)
	const localUser = getUserFromLocalStorage() as UserProfile | null
	const [deleteOpen, setDeleteOpen] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const email = 'user@example.com'
	const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)

	const [selectedImage, setSelectedImage] = useState<string | null>(null)
	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleVerifyOpen = () => setOpenDialog(true)
	const handleVerifyClose = () => setOpenDialog(false)

	const handleVerify = async (otp: string) => {
		try {
			const body: VerifyOtpRequest = {
				otp,
				type: 'email',
				emailId: localUser?.emailId?.toLowerCase(),
			}

			const response = await dispatch(verifyReautheticateOtp(body))

			if (response.meta?.requestStatus === 'rejected' || response.payload?.status === 400) {
				setIsIncorrectOtp(true)
				return false
			}

			if (response.payload?.data) {
				const deleteResponse = await dispatch(deleteUser())

				if (deleteResponse.meta.requestStatus !== 'rejected' && deleteResponse.payload) {
					setIsIncorrectOtp(false)
					handleVerifyClose()
					handleClose()
					handleDeleteClose()
					dispatch(logoutApi(''))
					removeTokenFromLocalStorage()
					removeAuthTypeFromLocalStorage()
					removeUserFromLocalStorage()
					window.location.href = routePaths.home
					dispatch({ type: 'logout' })
				}
			}

			return response.meta?.requestStatus === 'fulfilled'
		} catch (error) {
			setIsIncorrectOtp(true)
			return false
		}
	}

	const sendOtpToEmail = () => {
		//'Sending OTP to email:', email, setEmail
	}

	const handleDeleteOpen = () => setDeleteOpen(true)
	const handleDeleteClose = () => setDeleteOpen(false)

	const handleDelete = async () => {
		await dispatch(sendReautheticateOtp())
		handleDeleteClose()
		handleVerifyOpen()
	}

	useEffect(() => {
		if (userData) {
			setUser(userData)
		} else {
			const localStorageUser = getUserFromLocalStorage() as UserProfile | null
			setUser(localStorageUser)
		}
	}, [userData])

	useEffect(() => {
		if (user) {
			const country = user.country
				? { id: Country.getAllCountries().find((c) => c.name === user.country)?.isoCode || '', label: user.country }
				: null
			let stateOption: Option | null = null
			let cityOption: Option | null = null

			if (country) {
				const states = State.getStatesOfCountry(country.id)
				const matchedState = states.find((s) => s.name === user.state)
				if (matchedState) {
					stateOption = { id: matchedState.isoCode, label: matchedState.name }

					if (user.city) {
						const cities = City.getCitiesOfState(country.id, matchedState.isoCode)
						const matchedCity = cities.find((c) => c.name === user.city)
						// if (matchedCity) {
						// 	cityOption = { id: matchedCity.name, label: matchedCity.name }
						// }
						// If city is in the list, use it; otherwise, use the user's entered city directly.
						cityOption = matchedCity ? { id: matchedCity.name, label: matchedCity.name } : { id: user.city, label: user.city }
					}
				}
			}

			const updatedProfileForm = {
				fullName: user.fullName,
				displayName: user.displayName,
				emailId: user.emailId,
				phone: user.phoneNumber?.toString() ?? '',
				dob: user.dob ?? null, // Now storing as string
				gender: user.gender
					? { id: user.gender.toString(), label: user.gender === 1 ? 'Male' : user.gender === 2 ? 'Female' : 'Other' }
					: null,
				country,
				state: stateOption,
				city: cityOption,
				profession: user.profession ?? '',
			}
			dispatch(setProfileData(updatedProfileForm))
			setInitialFormData(updatedProfileForm)
		}
	}, [user, dispatch])

	const handleChange = (key: keyof typeof profileForm, value: Option | string | Date | null) => {
		if (key === 'dob' && value instanceof Date) {
			dispatch(setProfileData({ [key]: value.toISOString().split('T')[0] }))
		} else {
			dispatch(setProfileData({ [key]: value }))
		}

		if (key === 'country') {
			dispatch(setProfileData({ state: null, city: null }))
		} else if (key === 'state') {
			dispatch(setProfileData({ city: null }))
		}
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
		if (event.key === 'Enter' || event.key === 'ArrowDown') {
			event.preventDefault()
			if (isFormValid && isFormChanged) {
				handleSubmit()
			}
		}
	}

	const handleSubmit = async () => {
		if (!user) {
			return
		}

		const completeProfileRequest: CompleteProfileRequest = {
			email: user.emailId,
			fullName: profileForm.fullName?.trim(),
			displayName: profileForm.displayName?.trim(),
			phoneNumber: profileForm?.phone ? Number(profileForm.phone) : 0,
			dob: profileForm.dob ?? '', // Now using the string directly
			gender: profileForm.gender ? Number(profileForm.gender.id) : 0,
			country: profileForm.country?.label ?? '',
			state: profileForm.state?.label ?? '',
			city: profileForm.city?.label ?? '',
			profession: profileForm.profession?.trim() ?? '',
		}

		const response = await dispatch(completeUserProfileApi(completeProfileRequest))
		if (response.meta.requestStatus === 'fulfilled') {
			handleClose()
		}
	}

	const isFormValid = useMemo(() => {
		return (
			profileForm.fullName?.trim() &&
			profileForm.displayName?.trim() &&
			profileForm.emailId &&
			profileForm.phone &&
			profileForm.phone.length === 10 &&
			profileForm.dob &&
			profileForm.gender &&
			profileForm.country &&
			profileForm.state &&
			profileForm.city &&
			profileForm.profession?.trim()
		)
	}, [profileForm])

	const isFormChanged = useMemo(() => {
		if (!initialFormData) return false
		return Object.keys(profileForm).some((key) => {
			const formKey = key as keyof typeof profileForm
			if (formKey === 'dob') {
				return profileForm[formKey] !== initialFormData[formKey]
			}
			if (formKey === 'gender' || formKey === 'country' || formKey === 'state' || formKey === 'city') {
				return profileForm[formKey]?.id !== initialFormData[formKey]?.id
			}
			return profileForm[formKey] !== initialFormData[formKey]
		})
	}, [profileForm, initialFormData])

	const isButtonDisabled = !isFormValid || !isFormChanged

	const handleUploadProfile = async (file: File, fileName: string) => {
		let reqData = {
			body: {
				profilePicture: fileName,
			},
			isUploaded: false,
		}

		const res = await dispatch(uploadProfilePic(reqData))
		if (res && res.meta.requestStatus === 'fulfilled') {
			const s3Link = res?.payload?.data.data

			const contentType = file.type
			const s3Res = await dispatch(uploadImageToS3({ s3Link, file, contentType }))
			if (s3Res && s3Res.meta.requestStatus === 'fulfilled') {
				reqData = {
					...reqData,
					isUploaded: true,
				}

				const res2 = await dispatch(uploadProfilePic(reqData))
				if (res2 && res2.meta.requestStatus === 'fulfilled') {
					return true
				}
			}
		} else return false
	}

	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file) {
			const imageUrl = URL.createObjectURL(file)
			const newFileName = `${Date.now()}_${file.name.replace(/ +/g, '_')}`
			const uploaded = await handleUploadProfile(file, newFileName)
			if (uploaded) {
				setSelectedImage(imageUrl)
				dispatch(fetchProfile())
			}
		}
	}

	const handleUploadClick = () => {
		fileInputRef.current?.click()
	}

	return (
		<CustomDialog
			open={open}
			handleClose={handleClose}
			titileIcon={true}
			width={'60%'}
			height={'90%'}
			sx={{ padding: isTab ? '25px 10px' : '25px 10px' }}
		>
			<Box sx={{ ...flexStyles.flexColumnCenter }}>
				{/* <CustomIcon name={iconKeys.getFitNowIcon} style={{ width: '200px', height: '89px' }} svgStyle='width: 200px; height: 89px' /> */}
				{!isFormValid && (
					<Typography variant={typographyKeys.h5} sx={{ mt: '28px' }}>
						{localize(localeKeys.profileMsg)}
					</Typography>
				)}

				<Box sx={{ ...(isTab ? flexStyles.flexColumnCenter : flexStyles.flexRow), mt: '28px', gap: '29px', width: '100%' }}>
					<Box
						sx={{
							position: 'relative',
							height: '88px',
						}}
					>
						<IconButton size='small' onClick={handleUploadClick} sx={{ width: '88px', height: '88px', position: 'relative' }}>
							<HexAvatar imageUrl={selectedImage ?? user?.profilePictureUrl ?? ''} index={0} widthsx='88px' heightsx='88px' />

							<input type='file' ref={fileInputRef} onChange={handleFileUpload} style={{ display: 'none' }} accept='image/*' />
							<CustomIcon
								name={iconKeys.editProfile}
								style={{ width: '30px', height: '30px', position: 'absolute', right: '11px', bottom: '-3px' }}
							/>
						</IconButton>
					</Box>
					<form onKeyDown={handleKeyDown} style={{ width: '100%' }}>
						<ProfileDialogForm
							data={profileForm}
							handleChange={handleChange}
							content={
								<Box
									sx={{
										mt: '28px',
										width: '100%',
										...(isTab ? flexStyles.flexCenter : flexStyles.flexCenterSpaceBetween),
										gap: '10px',
									}}
								>
									<CustomIcon
										name={iconKeys.delete}
										style={{ cursor: 'pointer' }}
										onClick={() => {
											handleDeleteOpen()
										}}
									/>
									<CustomizedButton
										textprops={{
											text: localize(localeKeys.update),
										}}
										sxprops={{
											minWidth: isTab ? '100px' : '170px',
											borderRadius: '60px',
											backgroundColor: 'background.background2',
										}}
										onClick={handleSubmit}
										disabled={isButtonDisabled}
									/>
								</Box>
							}
						/>
					</form>
				</Box>
				<DeleteAccountDialog open={deleteOpen} onClose={handleDeleteClose} onDelete={handleDelete} />
				<VerifyAccount
					openDialog={openDialog}
					onVerifyClose={handleVerifyClose}
					onVerify={handleVerify}
					isIncorrectOtp={isIncorrectOtp}
					setIsIncorrectOtp={setIsIncorrectOtp}
					sendOtpToEmail={sendOtpToEmail}
					email={email}
					isDelete
				/>
			</Box>
		</CustomDialog>
	)
}

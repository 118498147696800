import React, { useState, useRef, useEffect } from 'react'
import { Popover, List, ListItem, ListItemText, InputAdornment, Box, Typography, Divider } from '@mui/material'
import myCountries from '../mutiple-auth/countries.json'
import 'flag-icons/css/flag-icons.min.css'
import CustomizedTextfield from '../mutiple-auth/CustomizedTextfield'
import { useLocalize } from './CustomHooks'
import { localeKeys } from '../resources/localeConstants'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import CustomIcon from './CustomIcon'

interface Country {
	name: string
	code: string
	dial_code: string
	min: number
	max: number
}

interface CustomPhoneInputProps {
	value: string
	onPhoneChange: (value: string) => void
	label: string
	error?: boolean
	helperText?: string
	height?: string
}

export default function CustomPhoneField({ value, onPhoneChange, label, error, helperText, height }: CustomPhoneInputProps) {
	const localize = useLocalize()
	const [selectedCountry, setSelectedCountry] = useState<Country>(() => {
		const india = myCountries.find((country) => country.code.toLowerCase() === 'in')
		return india || myCountries[0]
	})

	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
	const [searchQuery, setSearchQuery] = useState('')
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (value.length > selectedCountry.max) {
			onPhoneChange(value.slice(0, selectedCountry.max))
		}
	}, [value, selectedCountry, onPhoneChange])

	const handleCountryCodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setSearchQuery('')
	}

	const handleCountrySelect = (country: Country) => {
		setSelectedCountry(country)
		handleClose()
		inputRef.current?.focus()
	}

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value.replace(/\D/g, '')
		if (newValue.length <= selectedCountry.max) {
			onPhoneChange(newValue)
		}
	}

	const filteredCountries = myCountries.filter(
		(country) =>
			country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			country.code.toLowerCase().includes(searchQuery.toLowerCase()) ||
			country.dial_code.includes(searchQuery)
	)

	return (
		<>
			<CustomizedTextfield
				inputLabel={localize(label)}
				textFieldProps={{
					id: 'phonenumber',
					name: 'phonenumber',
					placeholder: localize(localeKeys.enterYourPhoneNumber),
					value: value,
					onChange: handlePhoneChange,
					error: error || (value.length > 0 && value.length < selectedCountry.min),
					inputProps: {
						maxLength: selectedCountry.max,
					},
				}}
				fieldSx={{ height: height ?? '44px' }}
				helperText={error ? helperText : value.length > 0 && value.length < selectedCountry.min ? localize('') : ''}
				inputRef={inputRef}
				icon={
					<InputAdornment position='start'>
						<Box
							onClick={handleCountryCodeClick}
							sx={{
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer',
								'&:hover': { opacity: 0.8 },
							}}
						>
							<span
								className={`fi fi-${selectedCountry.code.toLowerCase()}`}
								style={{ width: '16px', height: '12px', marginRight: '8px', borderRadius: '2px' }}
							/>
							<Typography variant={typographyKeys.h5} sx={{ color: 'text.defaultHeading' }}>
								{selectedCountry.dial_code}
							</Typography>

							<CustomIcon
								name={iconKeys.caretDown}
								svgStyle='width: 10px; height: 6px'
								style={{ paddingLeft: '10px', paddingRight: '10px' }}
							/>
							<Divider orientation='vertical' flexItem sx={{ m: 0 }} />
						</Box>
					</InputAdornment>
				}
				newColors={true}
			/>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{ sx: { p: '5px', width: '300px', backgroundImage: 'none', backgroundColor: 'background.surface' } }}
			>
				<CustomizedTextfield
					textFieldProps={{
						id: 'countrycodeandname',
						name: 'countrycodeandname',
						placeholder: localize(localeKeys.searchCountryOrCode),
						value: searchQuery,
						onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
					}}
					propSx={{ m: 1, width: 'calc(100% - 16px)', height: '20px' }}
					newColors={true}
				/>

				<List sx={{ maxHeight: 300, overflow: 'auto' }}>
					{filteredCountries.map((country) => (
						<ListItem
							component='button'
							key={country.code}
							onClick={() => handleCountrySelect(country)}
							sx={{
								bgcolor: 'background.surface',
								cursor: 'pointer',
								border: '1px solid',
								borderColor: 'border.main',
								'&:hover': {
									backgroundColor: 'background.background2',
								},
							}}
						>
							<ListItemText
								primary={
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<span
											className={`fi fi-${country.code.toLowerCase()}`}
											style={{ width: '24px', height: '18px', marginRight: '8px', borderRadius: '2px' }}
										/>
										<Typography variant={typographyKeys.body1} sx={{ color: 'text.defaultHeading' }}>
											{country.name}
										</Typography>
										<Typography
											variant={typographyKeys.body1}
											style={{ marginLeft: 'auto' }}
											sx={{ color: 'text.defaultHeading' }}
										>
											{country.dial_code}
										</Typography>
									</Box>
								}
							/>
						</ListItem>
					))}
				</List>
			</Popover>
		</>
	)
}

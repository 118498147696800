'use client'
import React, { ReactElement } from 'react'
import {
	Box,
	FormControl,
	FormControlProps,
	FormHelperText,
	InputAdornment,
	InputBaseComponentProps,
	InputLabel,
	OutlinedInput,
	OutlinedInputProps,
	SxProps,
	Typography,
	TypographyProps,
	useTheme,
} from '@mui/material'
import { getColors } from './themeColors'
import { useFontStyles } from './typography'
import { AnyObject } from '../utils/utils'

type FieldSXProps = {
	backgroundColor?: string
	borderRadius?: string
	placeholderStyle?: React.CSSProperties
	borderRight?: string
	paddingLeft?: string
	paddingRight?: string
	textStyle?: React.CSSProperties
	borderFocusColor?: string
	borderColor?: string
}

interface CustomTextFieldPropsProps {
	required?: boolean
	error?: boolean
	helperText?: string
	helperTextStyle?: SxProps
	formControlProps?: FormControlProps
	typoProps?: TypographyProps
	textFieldProps: {
		id: string
		name: string
	} & OutlinedInputProps
	fieldSx?: FieldSXProps & SxProps
	propSx?: InputBaseComponentProps & {
		height?: string
		maxLength?: string
		minLength?: string
	}
	charCount?: number
	charCountNeeded?: boolean
	onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
	inputLabel?: string
	newColors?: boolean
	icon?: ReactElement
	inputRef?: React.Ref<AnyObject> | null
	centerAligned?: boolean // New prop for center alignment
}

const CustomizedTextfield = ({ ...props }: CustomTextFieldPropsProps): ReactElement => {
	const theme = useTheme()
	const fontStyles = useFontStyles()
	const colors = getColors(props.newColors || false)

	return (
		<FormControl sx={{ width: '100%', gap: '5px' }} variant='outlined' {...props.formControlProps}>
			{props?.inputLabel && (
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '5px' }}>
					<Typography
						sx={{
							display: 'inline',
							color: colors.textPrimary,
							...fontStyles.body3,
						}}
						variant='body2'
					>
						{props?.inputLabel}
					</Typography>
					{props?.textFieldProps?.required && (
						<Typography sx={{ display: 'inline', color: colors.red, ...fontStyles.body2 }} variant='body2'>
							{'*'}
						</Typography>
					)}
				</Box>
			)}

			{props?.textFieldProps?.label && (
				<>
					<InputLabel
						htmlFor={props?.textFieldProps?.id}
						sx={{
							...theme.typography.body2,
							p: 0,
							opacity: 1,
							color:
								!props?.textFieldProps?.readOnly && props?.error
									? colors.red
									: props?.textFieldProps?.value &&
									  typeof props.textFieldProps.value === 'string' &&
									  props.textFieldProps.value.length > 0
									? colors.orangeMain
									: colors.greyLightHover,

							'&.Mui-focused': {
								color: !props?.textFieldProps?.readOnly && props?.error ? colors.red : colors.orangeMain,
								marginTop: '-3px',
							},
							marginTop: '-3px',
							...fontStyles.body3,
						}}
					>
						{props?.textFieldProps?.label}
						{props?.textFieldProps?.required && (
							<Typography sx={{ display: 'inline', color: colors.red, ...fontStyles.body3 }} variant='body2'>
								{' '}
								*
							</Typography>
						)}
					</InputLabel>
				</>
			)}

			<OutlinedInput
				fullWidth
				type='text'
				multiline={false}
				inputRef={props.inputRef ?? null}
				autoFocus={false}
				disabled={false}
				autoComplete={'one-time-code'}
				startAdornment={props.icon && <InputAdornment position='start'>{props.icon}</InputAdornment>}
				inputProps={{
					sx: {
						...props.propSx,
						minHeight: props?.propSx?.height ?? '46px',
						maxHeight: props?.propSx?.height ?? '46px',
						p: props.textFieldProps?.multiline ? 0 : '0px',
						overflow: props.textFieldProps?.multiline && 'auto !important',
						'::-webkit-scrollbar': {
							display: 'none',
						},
					},
					maxLength: props?.propSx?.maxLength,
					minLength: props?.propSx?.minLength,
				}}
				sx={{
					...props.fieldSx,
					'& input::placeholder': props?.fieldSx?.placeholderStyle ?? {
						...theme?.typography?.body2,
						...fontStyles.body2,
						color: colors.textTertiary,
						opacity: 1,
					},
					backgroundColor: (props?.textFieldProps?.readOnly ? colors.greyLightBorder : props.fieldSx?.backgroundColor) ?? colors.white,
					borderRadius: props.fieldSx?.borderRadius ?? '12px',
					'& fieldset': {
						borderRight: props.fieldSx?.borderRight,
					},
					'&.MuiOutlinedInput-root': {
						'& input': {
							...(props.fieldSx?.textStyle ?? {
								...theme?.typography?.body1,
								...fontStyles.body3,
								color: colors.textPrimary,
							}),
							textAlign: props.centerAligned ? 'center' : 'left', // Add center alignment
						},
						'& textarea': props.fieldSx?.textStyle ?? {
							...theme?.typography?.body1,
							...fontStyles.body3,
							color: colors.textPrimary,
						},
						'&:hover fieldset': {
							borderWidth: '1px',
							borderColor:
								(props?.textFieldProps?.readOnly
									? colors.greyLightActive
									: !props?.textFieldProps?.readOnly && props?.error
									? colors.red
									: props.textFieldProps.disabled
									? colors.greyLightHover
									: props.fieldSx?.borderFocusColor) ?? colors.orangeMain,
						},
						'&.Mui-focused fieldset': {
							borderWidth: '1px',
							borderColor:
								(props?.textFieldProps?.readOnly
									? colors.greyLightActive
									: !props?.textFieldProps?.readOnly && props?.error
									? colors.red
									: props.textFieldProps.disabled
									? colors.greyLightHover
									: props.fieldSx?.borderFocusColor) ?? colors.orangeMain,
						},
						'&>fieldset': {
							borderWidth: '1px',
							borderColor: props?.textFieldProps?.readOnly
								? colors.greyLightActive
								: !props?.textFieldProps?.readOnly && props?.error
								? colors.red
								: props.fieldSx?.borderColor ?? 'border.main',
						},
					},
				}}
				onBlur={props?.textFieldProps?.readOnly ? undefined : props.textFieldProps.onBlur}
				onFocus={props?.textFieldProps?.readOnly ? undefined : props.textFieldProps.onFocus}
				{...props.textFieldProps}
			/>
			{props.helperText && (
				<FormHelperText
					sx={{
						m: 0,
						p: '4px 8px 0px 8px',
						...props.helperTextStyle,
					}}
				>
					<Typography variant='body2' sx={{ color: colors.red, ...fontStyles.body3 }}>
						{props.helperText}
					</Typography>
				</FormHelperText>
			)}

			{props.charCountNeeded ? (
				<Typography sx={{ position: 'absolute', p: '5px', right: 0, bottom: 0 }} variant='body2'>
					{`${props.charCount}/${props.propSx?.maxLength}`}
				</Typography>
			) : null}
		</FormControl>
	)
}

export default CustomizedTextfield
